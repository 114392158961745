import React, { useState } from 'react';

import css from './PreCheckoutConfirmModal.module.css';
import { Modal, NamedLink, PrimaryButton } from '../../../components';
import { bool, func, string } from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

const PreCheckoutConfirmModal = ({
  rootClassName,
  className,
  isOpen,
  onClose,
  onConfirm,
  onManageDisableScrolling,
}) => {
  const classes = classNames(rootClassName || css.root, className);
  const intl = useIntl();
  const [clicked1, setClicked1] = useState(false);
  const [clicked2, setClicked2] = useState(false);

  const disabledNext = !clicked1 || !clicked2;

  return (
    <Modal
      id="preCheckoutConfirmModal"
      isOpen={isOpen}
      onClose={onClose}
      usePortal={true}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div className={classes}>
        <p className={css.confirmationText}>
          {intl.formatMessage(
            { id: 'PreCheckoutConfirmModal.content' },
            {
              link1: (
                <NamedLink
                  name="CMSPage"
                  params={{ pageId: 'prohibited-items' }}
                  target="_blank"
                  onClick={() => setClicked1(true)}
                >
                  {intl.formatMessage({ id: 'PreCheckoutConfirmModal.content.link1' })}
                </NamedLink>
              ),
              link2: (
                <NamedLink
                  name="CMSPage"
                  params={{ pageId: 'booking-agreement' }}
                  target="_blank"
                  onClick={() => setClicked2(true)}
                >
                  {intl.formatMessage({ id: 'PreCheckoutConfirmModal.content.link2' })}
                </NamedLink>
              ),
            }
          )}
        </p>
        <PrimaryButton
          disabled={disabledNext}
          title={intl.formatMessage({ id: 'PreCheckoutConfirmModal.continueBtn.title' })}
          onClick={onConfirm}
        >
          {disabledNext
            ? intl.formatMessage({ id: 'PreCheckoutConfirmModal.continueBtn.title' })
            : intl.formatMessage({ id: 'PreCheckoutConfirmModal.continueBtn' })}
        </PrimaryButton>
      </div>
    </Modal>
  );
};

PreCheckoutConfirmModal.defaultProps = {
  rootClassName: null,
  className: null,
};

PreCheckoutConfirmModal.propTypes = {
  rootClassName: string,
  className: string,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  onManageDisableScrolling: func.isRequired,
};

export default PreCheckoutConfirmModal;
